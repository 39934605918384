import React, { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';

const Achivement = () => {

    //counter code
    const targetRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Function to run when the target div is in view
                    handleScrollIntoView();
                }
            },
            {
                root: null, // Use the viewport as the container
                rootMargin: '0px',
                threshold: 0.1 // Trigger when 10% of the target is visible
            }
        );

        const currentTarget = targetRef.current;
        if (currentTarget) {
            observer.observe(currentTarget);
        }

        return () => {
            if (currentTarget) {
                observer.unobserve(currentTarget);
            }
        };
    }, []);

    const [projectCount, setProjectCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const [experienceCount, setExperienceCount] = useState(0);

    const handleScrollIntoView = () => {
        console.log('Div is in view!');

        let intervalId;

        function counter(state, limit, time) {
            intervalId = setInterval(() => {
                state((prevCount) => {
                    if (prevCount < limit) {
                        return prevCount + 1;
                    } else {
                        clearInterval(intervalId);
                        return prevCount;
                    }
                });
            }, time);
        }

        counter(setProjectCount, 1500, 0.01); // Call the counter function when the component renders
        counter(setCustomerCount, 200, 15);
        counter(setExperienceCount, 3, 1000);

        // Cleanup the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };


    };

    return (
        // main div 
        <div className='flex flex-col justify-center items-center w-full h-fit gap-5 bg-white'>
            <Fade direction='down'>
                <h2 className='montserrat cursor-default md:text-9xl text-5xl text-[#F7E2DD] font-bold mt-14'>ACHIVEMENTS</h2>
            </Fade>
            {/* row div */}
            <Fade direction='left'>
                <div className='flex flex-row justify-center items-center gap-20 flex-wrap mt-14 mb-14'>

                    {/* single card  */}

                    <div className='flex flex-col gap-2 justify-center items-center md:w-96 w-11/12 h-fit'>
                        {/* heading number  */}
                        <h3 className='montserrat cursor-default md:text-9xl text-8xl text-[#875E54] font-bold'>{projectCount}+</h3>
                        <p className='montserrat cursor-default md:text-5xl text-4xl font-medium text-[#707070] text-center'>PROJECT COMPLETED</p>
                    </div>

                    {/* single card  */}
                    <div ref={targetRef} className='flex flex-col gap-2 justify-center items-center md:w-96 w-11/12 h-fit'>
                        {/* heading number  */}
                        <h3 className='montserrat cursor-default md:text-9xl text-8xl text-[#875E54] font-bold'>{customerCount}+</h3>
                        <p className='montserrat cursor-default md:text-5xl text-center text-4xl font-medium text-[#707070]'>CUSTOMERS</p>
                    </div>
                    {/* single card  */}
                    <div className='flex flex-col gap-2 justify-center items-center md:w-96 w-11/12 h-fit'>
                        {/* heading number  */}
                        <h3 className='montserrat cursor-default md:text-9xl text-8xl text-[#875E54] font-bold'>0{experienceCount}+</h3>
                        <p className='montserrat cursor-default md:text-5xl text-center text-4xl font-medium text-[#707070]'>EXPERIENCE</p>
                    </div>
                </div>

            </Fade>
        </div >
    )
}

export default Achivement
