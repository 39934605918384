import React from 'react';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import Hamburger from '../Components/Hamburger';
import { Helmet } from 'react-helmet';
import Media from '../Assests/Media';

const About = () => {
    return (
        <>
            <Helmet>
                <title>About Us - Sujal Arts | Expert Graphic Design, Printing, and Animation Services</title>
                <meta name="description" content="Learn more about Sujal Jain, a skilled graphic designer with over 3 years of experience, offering top-notch design, printing, and animation services in Bhiwandi, Maharashtra. Specializing in tailored solutions for religious events, particularly Jainism." />
                <meta name="keywords" content="Sujal Arts about us, Sujal Jain graphic designer, Bhiwandi graphic design services, Maharashtra graphic design, printing services, animation services, Jainism design solutions, religious event designs, graphic designer experience, professional design services" />
                <meta name="author" content="Sujal Jain" />
                <meta name="robots" content="index, follow" />
                <meta name="rating" content="General" />
                <meta name="distribution" content="global" />
                <link rel="canonical" href="https://www.sujalarts.in/about" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="About Us - Sujal Arts | Expert Graphic Design, Printing, and Animation Services" />
                <meta property="og:description" content="Learn more about Sujal Jain, a skilled graphic designer with over 3 years of experience, offering top-notch design, printing, and animation services in Bhiwandi, Maharashtra. Specializing in tailored solutions for religious events, particularly Jainism." />
                <meta property="og:url" content="https://www.sujalarts.in/about" />
                <meta property="og:image" content={Media.logo} />
                <meta property="og:image:alt" content="Sujal Jain graphic designer" />
                <meta property="og:site_name" content="Sujal Arts" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:locale:alternate" content="en_US" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About Us - Sujal Arts | Expert Graphic Design, Printing, and Animation Services" />
                <meta name="twitter:description" content="Learn more about Sujal Jain, a skilled graphic designer with over 3 years of experience, offering top-notch design, printing, and animation services in Bhiwandi, Maharashtra. Specializing in tailored solutions for religious events, particularly Jainism." />
                <meta name="twitter:image" content={Media.logo} />
                <meta name="twitter:image:alt" content="Sujal Jain graphic designer" />
                <meta name="twitter:site" content="@sujalarts" />
                <meta name="twitter:creator" content="@sujalarts" />

                {/* Google / Schema.org */}
                <meta itemprop="name" content="About Us - Sujal Arts | Expert Graphic Design, Printing, and Animation Services" />
                <meta itemprop="description" content="Learn more about Sujal Jain, a skilled graphic designer with over 3 years of experience, offering top-notch design, printing, and animation services in Bhiwandi, Maharashtra. Specializing in tailored solutions for religious events, particularly Jainism." />
                <meta itemprop="image" content={Media.logo} />

                {/* Local Business Schema.org */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "LocalBusiness",
                        "name": "Sujal Arts",
                        "image": Media.logo,
                        "@id": "",
                        "url": "https://www.sujalarts.in",
                        "telephone": "+91-8080446754",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Global Warehouse, F Building, Gala No. 14, Near Narayan Tadpatri, opp. Kasturi Complex, Dapoda Road, Anjur Phata",
                            "addressLocality": "Bhiwandi",
                            "addressRegion": "MH",
                            "postalCode": "421302",
                            "addressCountry": "IN"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 19.2813,
                            "longitude": 73.0483
                        },
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "sameAs": [
                            "https://www.facebook.com/profile.php?id=100082135998756&mibextid=ZbWKwL",
                            "https://www.instagram.com/sujalarts2391",
                            "https://www.twitter.com/sujalarts"
                        ]
                    })}
                </script>

                {/* About Us Schema.org */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Person",
                        "name": "Sujal Jain",
                        "description": "Sujal Jain, a graphic designer with over 3 years of experience, offering creative design, printing, and animation services. Based in Bhiwandi, Maharashtra, specializing in tailored solutions for religious events, particularly Jainism.",
                        "image": Media.logo,
                        "url": "https://www.sujalarts.in/about",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Global Warehouse, F Building, Gala No. 14, Near Narayan Tadpatri, opp. Kasturi Complex, Dapoda Road, Anjur Phata",
                            "addressLocality": "Bhiwandi",
                            "addressRegion": "MH",
                            "postalCode": "421302",
                            "addressCountry": "IN"
                        },
                        "sameAs": [
                            "https://www.facebook.com/profile.php?id=100082135998756&mibextid=ZbWKwL",
                            "https://www.instagram.com/sujalarts2391",
                            "https://www.twitter.com/sujalarts"
                        ]
                    })}
                </script>

                {/* Additional Meta Tags */}
                <meta name="robots" content="index, follow" />
                <meta name="rating" content="General" />
                <meta name="distribution" content="global" />
                <link rel="canonical" href="https://www.sujalarts.in/about" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:locale:alternate" content="en_US" />
                <meta name="theme-color" content="#317EFB" />
            </Helmet>

            <Hamburger />
            <div className='flex flex-col justify-center items-center w-full h-fit serviceBg lg:pt-[150px] pt-[40px]'>
                <Navbar />
                {/* heading  */}
                <h2 className='montserrat font-bold lg:text-9xl text-5xl text-[#875E54] mb-[80px]  mt-[80px]'>About us</h2>

                {/* main container  */}
                <div className='flex flex-col justify-center items-center w-11/12 h-fit boxshadow bg-[#FFFFFF] lg:p-28 p-6 lg:rounded-3xl rounded-xl mb-32'>
                    <p className='raleway lg:leading-normal leading-relaxed lg:text-4xl text-xl text-[#875E54] font-medium w-full text-wrap tracking-wide '>Welcome to our Graphic Designing Services! I'm Sujal Jain, a 21-year-old graphic designer with over 3 years of experience. Based in Bhiwandi, Maharashtra, I hold qualifications in SSC, HSC, and b.com in Accounting and Finance.
                        <br />
                        <br />
                        <br />
                        We specialize in a wide variety of creative solutions, including designing, printing, and animation, ensuring that every aspect of your project is handled with expertise and care. Our services are particularly focused on providing tailored solutions for religious events, with a special emphasis on Jainism. We create beautiful and meaningful designs that capture the essence of your celebrations and traditions.
                        <br />
                        <br />
                        <br />
                        Whether you need stunning graphics, high-quality prints, or engaging animations, we are dedicated to bringing your vision to life. Trust us to deliver exceptional results that exceed your expectations and make your events truly memorable.
                        <br />
                        <br />
                        <br />
                    </p>

                </div>
                <Footer />

            </div>
        </>

    )
}

export default About
