import React, { useEffect, useState } from 'react';
import Footer from '../Components/Footer';
import emailjs from '@emailjs/browser';
import { useLocation } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Hamburger from '../Components/Hamburger';
import { Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet';
import Media from '../Assests/Media';

const Contact = () => {

    const handleNavigate = (url) => {
        window.location.href = url
    }

    const location = useLocation();

    const [formData, setFormData] = useState({
        user_name: '',
        user_lastname: '',
        user_email: '',
        user_phone: '',
        project_description: '',
    });

    useEffect(() => {
        if (location.state && location.state.email) {
            setFormData((prevState) => ({
                ...prevState,
                user_email: location.state.email,
            }));
        }
    }, [location.state]);


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_dbojk1o', 'template_uko0fn7', e.target, 'EJ1Pt2Anc0ov7UMXu')
            .then(
                () => {
                    alert("Message Sent Succesfully");
                    alert("You will get reply within an hour");
                    console.log('SUCCESS!');
                    // Reset form data after successful submission
                    setFormData({
                        user_name: '',
                        user_lastname: '',
                        user_email: '',
                        user_phone: '',
                        project_description: '',
                    });
                },
                (error) => {
                    alert("Failed to send message");
                    console.log('FAILED...', error);
                },
            );
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    return (
        <>
            <Helmet>
                <title>Contact Us - Sujal Arts | Get in Touch for Graphic Design, Printing, and Animation Services</title>
                <meta name="description" content="Contact Sujal Arts for expert graphic design, printing, and animation services. Get in touch via phone, email, or visit our location in Bhiwandi, Maharashtra for personalized solutions. Reach out to us for all your design needs." />
                <meta name="keywords" content="Sujal Arts contact, Sujal Jain graphic designer, Bhiwandi contact information, Maharashtra design services, graphic design contact, printing services contact, animation services contact, email Sujal Arts, phone number Sujal Jain, contact Sujal Arts" />
                <meta name="author" content="Sujal Jain" />
                <meta name="robots" content="index, follow" />
                <meta name="rating" content="General" />
                <meta name="distribution" content="global" />
                <link rel="canonical" href="https://www.sujalarts.in/contact" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Contact Us - Sujal Arts | Get in Touch for Graphic Design, Printing, and Animation Services" />
                <meta property="og:description" content="Contact Sujal Arts for expert graphic design, printing, and animation services. Get in touch via phone, email, or visit our location in Bhiwandi, Maharashtra for personalized solutions. Reach out to us for all your design needs." />
                <meta property="og:url" content="https://www.sujalarts.in/contact" />
                <meta property="og:image" content={Media.logo} />
                <meta property="og:image:alt" content="Contact Sujal Arts graphic design services" />
                <meta property="og:site_name" content="Sujal Arts" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:locale:alternate" content="en_US" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact Us - Sujal Arts | Get in Touch for Graphic Design, Printing, and Animation Services" />
                <meta name="twitter:description" content="Contact Sujal Arts for expert graphic design, printing, and animation services. Get in touch via phone, email, or visit our location in Bhiwandi, Maharashtra for personalized solutions. Reach out to us for all your design needs." />
                <meta name="twitter:image" content={Media.logo} />
                <meta name="twitter:image:alt" content="Contact Sujal Arts graphic design services" />
                <meta name="twitter:site" content="@sujalarts" />
                <meta name="twitter:creator" content="@sujalarts" />

                {/* Google / Schema.org */}
                <meta itemprop="name" content="Contact Us - Sujal Arts | Get in Touch for Graphic Design, Printing, and Animation Services" />
                <meta itemprop="description" content="Contact Sujal Arts for expert graphic design, printing, and animation services. Get in touch via phone, email, or visit our location in Bhiwandi, Maharashtra for personalized solutions. Reach out to us for all your design needs." />
                <meta itemprop="image" content={Media.logo} />

                {/* Local Business Schema.org */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "LocalBusiness",
                        "name": "Sujal Arts",
                        "image": Media.logo,
                        "@id": "",
                        "url": "https://www.sujalarts.in",
                        "telephone": "+91-8080446754",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Global Warehouse, F Building, Gala No. 14, Near Narayan Tadpatri, opp. Kasturi Complex, Dapoda Road, Anjur Phata",
                            "addressLocality": "Bhiwandi",
                            "addressRegion": "MH",
                            "postalCode": "421302",
                            "addressCountry": "IN"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 19.2813,
                            "longitude": 73.0483
                        },
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "sameAs": [
                            "https://www.facebook.com/profile.php?id=100082135998756&mibextid=ZbWKwL",
                            "https://www.instagram.com/sujalarts2391",
                            "https://www.twitter.com/sujalarts"
                        ]
                    })}
                </script>

                {/* Contact Page Schema.org */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "ContactPage",
                        "name": "Contact Us - Sujal Arts",
                        "description": "Contact Sujal Arts for graphic design, printing, and animation services. Reach out through phone, email, or visit our office in Bhiwandi, Maharashtra for personalized solutions.",
                        "url": "https://www.sujalarts.in/contact",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "Customer Service",
                            "telephone": "+91-8080446754",
                            "email": "sujalarts239@gmail.com",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "Global Warehouse, F Building, Gala No. 14, Near Narayan Tadpatri, opp. Kasturi Complex, Dapoda Road, Anjur Phata",
                                "addressLocality": "Bhiwandi",
                                "addressRegion": "MH",
                                "postalCode": "421302",
                                "addressCountry": "IN"
                            }
                        },
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.sujalarts.in/contact?q={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    })}
                </script>

                {/* Additional Meta Tags */}
                <meta name="robots" content="index, follow" />
                <meta name="rating" content="General" />
                <meta name="distribution" content="global" />
                <link rel="canonical" href="https://www.sujalarts.in/contact" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:locale:alternate" content="en_US" />
                <meta name="theme-color" content="#317EFB" />
            </Helmet>

            <Hamburger />
            <div className='flex flex-col justify-center items-center w-full h-fit serviceBg lg:pt-[150px] pt-[40px]'>
                <Navbar />
                {/* heading */}
                <Fade direction='down'>
                    <h2 className='montserrat font-bold lg:text-9xl text-5xl text-[#875E54] mb-[80px] mt-[80px]'>CONTACT</h2>
                </Fade>
                <div className='flex flex-col justify-center items-center w-full h-fit lg:p-20 p-10'>
                    {/* address */}
                    {/* Main container */}
                    <div onClick={() => handleNavigate('https://www.google.com/maps/place/729Q%2BGJ2+Global+Warehouse,+Dapode,+Kailasnagar,+Maharashtra+421302/data=!4m2!3m1!1s0x3be7bc4cc0240e1d:0x4be57d568ee1b434?utm_source=mstt_1&entry=gps&coh=192189&g_ep=CAESCjExLjEzNi4xMDEYACDXggMqWiw5NDIzMTc5OSw5NDIxMjQ5Niw5NDIwNzM5NCw5NDIwNzUwNiw5NDIwODUwNiw5NDIxNzUyMyw5NDIxODY1Myw0NzA4NzExOCw0NzA4NDM5Myw5NDIxMzIwMEICSU4%3D')} className='flex flex-col justify-start items-center w-full h-fit lg:pt-14 pt-7 lg:pb-14 pb-7 cursor-pointer'>
                        {/* small div */}
                        <div className='flex justify-center items-center lg:w-fit w-1/4 h-fit lg:rounded-3xl rounded-xl bg-[#875E54] z-10 absolute'>
                            <h2 className='montserrat text-white lg:text-5xl text-sm font-bold text-nowrap lg:pt-9 lg:pb-9 pt-4 pb-4 pr-9 pl-9'>ADDRESS</h2>
                        </div>
                        {/* container */}
                        <div className='flex flex-col justify-center items-start lg:w-1/2 w-11/12 h-fit rounded-3xl bg-white bg-transparent lg:mt-16 mt-8 lg:p-20 p-5 '>
                            <p className='poppins lg:leading-normal leading-relaxed lg:text-3xl text-xs text-[#875E54] font-medium w-full text-wrap tracking-widest text-center '>
                                Global Warehouse, F Building, Gala No. 14, Near Narayan Tadpatri, opp. Kasturi Complex,
                                Dapoda Road, Anjur Phata, Bhiwandi-421302
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-col justify-start items-center w-full h-fit lg:pt-14 pt-7 lg:pb-14 pb-7'>

                        {/* small div */}
                        <div className='flex justify-center items-center lg:w-fit w-1/4 h-fit lg:rounded-3xl rounded-xl bg-[#875E54] z-10 absolute'>
                            <h2 className='montserrat text-white lg:text-5xl text-sm font-bold text-nowrap lg:pt-9 lg:pb-9 pt-4 pb-4 pr-9 pl-9'>MOBILE</h2>
                        </div>
                        {/* container */}
                        <div className='flex flex-col justify-center items-start lg:w-1/2 w-11/12  h-fit rounded-3xl bg-white bg-transparent lg:mt-16 mt-8 lg:p-20 p-5 '>
                            <p className='poppins lg:leading-normal leading-relaxed lg:text-3xl text-sm text-[#875E54] font-medium w-full text-wrap tracking-widest text-center '>
                                <a href='tel: +91 8080446754' >
                                    8080446754
                                </a>
                                <span className='mr-2 ml-2'> | </span>
                                <a href='tel: +91 9834815633' >
                                    9834815633
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-col justify-start items-center w-full h-fit lg:pt-14 pt-7 lg:pb-14 pb-7'>
                        {/* small div */}
                        <div className='flex justify-center items-center lg:w-fit w-1/4 h-fit lg:rounded-3xl rounded-xl bg-[#875E54] z-10 absolute'>
                            <h2 className='montserrat text-white lg:text-5xl text-sm font-bold text-nowrap lg:pt-9 lg:pb-9 pt-4 pb-4 pr-9 pl-9'>EMAIL</h2>
                        </div>
                        {/* container */}
                        <div onClick={() => handleNavigate('https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=GTvVlcSHvbJLHwjklJzNBdcVChMHrPBkzCbtXRqTHLNFcZXKktHfftkvpLpHhnwgtDqwXBClCLRJL')} className='flex flex-col justify-center items-start lg:w-1/2 w-full  h-fit rounded-3xl bg-white bg-transparent lg:mt-16 mt-8 lg:p-20 p-5 cursor-pointer '>
                            <p className='poppins lg:leading-normal leading-relaxed lg:text-3xl text-xs text-[#875E54] font-medium w-full text-wrap tracking-widest text-center '>
                                sujalarts239@gmail.com
                            </p>
                        </div>

                    </div>
                </div>

                <form onSubmit={sendEmail} className='flex flex-col justify-center items-center w-full h-fit formBg'>
                    {/* div for 4 inputs */}
                    <div className='flex flex-row flex-wrap justify-center items-center w-full h-fit'>
                        {/* single input */}
                        <div className='flex flex-col gap-8 justify-center items-start w-4/5 lg:w-2/5 h-fit mt-[10px] lg:mt-[150px] mb-[10px] lg:mb-[150px] '>
                            <h2 className='poppins lg:text-5xl text-2xl font-medium text-[#875E54]'>First Name</h2>
                            <input
                                onChange={handleChange}
                                name="user_name"
                                value={formData.user_name}
                                className='w-4/5 bg-transparent h-16 input outline-none poppins lg:text-3xl text-xl font-medium text-[#875E54]'
                                type="text"
                            />
                        </div>

                        {/* single input */}
                        <div className='flex flex-col gap-8 justify-center items-start w-4/5 lg:w-2/5 h-fit mt-[10px] lg:mt-[150px] mb-[10px] lg:mb-[150px] '>
                            <h2 className='poppins lg:text-5xl text-2xl font-medium text-[#875E54]'>Last Name</h2>
                            <input
                                onChange={handleChange}
                                name="user_lastname"
                                value={formData.user_lastname}
                                className='w-4/5 bg-transparent h-16 input outline-none poppins lg:text-3xl text-xl font-medium text-[#875E54]'
                                type="text"
                                required
                            />
                        </div>

                        {/* single input */}
                        <div className='flex flex-col gap-8 justify-center items-start w-4/5 lg:w-2/5 h-fit mt-[10px] lg:mt-[150px] mb-[10px] lg:mb-[150px]'>
                            <h2 className='poppins lg:text-5xl text-2xl font-medium text-[#875E54]'>Email</h2>
                            <input
                                onChange={handleChange}
                                name="user_email"
                                value={formData.user_email}
                                className='w-4/5 bg-transparent h-16 input outline-none poppins lg:text-3xl text-xl font-medium text-[#875E54]'
                                type="email"
                            />
                        </div>

                        {/* single input */}
                        <div className='flex flex-col gap-8 justify-center items-start w-4/5 lg:w-2/5 h-fit mt-[10px] lg:mt-[150px] mb-[10px] lg:mb-[150px]'>
                            <h2 className='poppins lg:text-5xl text-2xl font-medium text-[#875E54]'>Phone Number</h2>
                            <input
                                onChange={handleChange}
                                name="user_phone"
                                value={formData.user_phone}
                                className='w-4/5 bg-transparent h-16 input outline-none poppins lg:text-3xl text-xl font-medium text-[#875E54]'
                                type="tel"
                                required
                            />
                        </div>
                    </div>
                    {/* single input */}
                    <div className='flex flex-col gap-8 justify-center items-start w-4/5 h-fit mt-[10px] lg:mt-[150px] mb-[10px] lg:mb-[150px]'>
                        <h2 className='poppins lg:text-5xl text-2xl font-medium text-[#875E54]'>Message</h2>
                        <input
                            onChange={handleChange}
                            name="project_description"
                            value={formData.project_description}
                            className='w-4/5 bg-transparent h-16 input outline-none poppins lg:text-3xl text-xl font-medium text-[#875E54]'
                            type="text"
                            placeholder='Write your message'
                            required
                        />
                    </div>
                    <button type='submit' className='flex justify-center items-center lg:w-fit w-3/5 h-fit lg:rounded-2xl rounded-sm bg-[#875E54] cursor-pointer mt-[100px] mb-[100px] hover:bg-[#F7E2DD]'>
                        <h2 className='montserrat hover:text-[#875E54] text-white lg:text-5xl text-sm font-bold text-nowrap lg:pt-9 lg:pb-9 pt-4 pb-4 pr-9 pl-9'>Submit</h2>
                    </button>
                </form>
                <Footer />
            </div >
        </>

    )
}

export default Contact;
