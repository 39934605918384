import React from 'react'
import '../Components/Hamburger.scss'
import { Link } from 'react-router-dom'
const Hamburger = () => {
    return (
        <div className='flex md:hidden mr-[400px] w-4/5'>
            {/* This checkbox will give us the toggle behavior, it will be hidden, but functional */}
            <input id="toggle" type="checkbox" />
            {/* IMPORTANT: Any element that we want to modify when the checkbox state changes go here, being "sibling" of the checkbox element */}
            {/* This label is tied to the checkbox, and it will contain the toggle "buttons" */}
            <label className="toggle-container" htmlFor="toggle">
                {/* If menu is open, it will be the "X" icon, otherwise just a clickable area behind the hamburger menu icon */}
                <span className="button button-toggle" />
            </label>
            {/* The nav menu */}
            <nav className="nav">
                <Link className="nav-item" to='/'>Home</Link>
                <Link className="nav-item" to='/portfolio'>Portfolio</Link>
                <Link className="nav-item" to='/service'>Service</Link>
                <Link className="nav-item" to='/about'>About us</Link>
                <Link className="nav-item" to='/contact'>Contact</Link>
            </nav>
        </div>



    )
}

export default Hamburger
