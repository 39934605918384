import React from 'react';
import Navbar from '../Components/Navbar.js'
import Footer from '../Components/Footer.js';
import Hamburger from '../Components/Hamburger.js';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet';
import Media from '../Assests/Media.js';

const Service = () => {
    return (
        <>
            <Helmet>
                <title>Services - Sujal Arts | Creative Designs, Printing, and Animation</title>
                <meta name="description" content="Discover the comprehensive services offered by Sujal Arts, including creative design, high-quality printing, and innovative animation solutions. Explore our service offerings and contact us for more details." />
                <meta name="keywords" content="Sujal Arts services, creative design, printing services, animation services, poster design, flyer design, visiting card design, banners, hoardings, e-invites, brochure design, pamphlet, standee design, gifting items design, stationery items, promotional designs, logo design, book printing, badges, flags, ID cards, gate banner set, custom prints, animation, motion poster, music video, advertising motion poster, GIF, e-invites animation" />
                <meta name="author" content="Sujal Jain" />
                <meta name="robots" content="index, follow" />
                <meta name="rating" content="General" />
                <meta name="distribution" content="global" />
                <link rel="canonical" href="https://www.sujalarts.in/service" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Services - Sujal Arts | Creative Designs, Printing, and Animation" />
                <meta property="og:description" content="Discover the comprehensive services offered by Sujal Arts, including creative design, high-quality printing, and innovative animation solutions. Explore our service offerings and contact us for more details." />
                <meta property="og:url" content="https://www.sujalarts.in/service" />
                <meta property="og:image" content={Media.design12} />
                <meta property="og:image:alt" content="Sujal Arts services" />
                <meta property="og:site_name" content="Sujal Arts" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:locale:alternate" content="en_US" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Services - Sujal Arts | Creative Designs, Printing, and Animation" />
                <meta name="twitter:description" content="Discover the comprehensive services offered by Sujal Arts, including creative design, high-quality printing, and innovative animation solutions. Explore our service offerings and contact us for more details." />
                <meta name="twitter:image" content={Media.print12} />
                <meta name="twitter:image:alt" content="Sujal Arts services" />
                <meta name="twitter:site" content="@sujalarts" />
                <meta name="twitter:creator" content="@sujalarts" />

                {/* Google / Schema.org */}
                <meta itemprop="name" content="Services - Sujal Arts | Creative Designs, Printing, and Animation" />
                <meta itemprop="description" content="Discover the comprehensive services offered by Sujal Arts, including creative design, high-quality printing, and innovative animation solutions. Explore our service offerings and contact us for more details." />
                <meta itemprop="image" content={Media.logo} />

                {/* Local Business Schema.org */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "LocalBusiness",
                        "name": "Sujal Arts",
                        "image": Media.design3,
                        "@id": "",
                        "url": "https://www.sujalarts.in",
                        "telephone": "+91-8080446754",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Global Warehouse, F Building, Gala No. 14, Near Narayan Tadpatri, opp. Kasturi Complex, Dapoda Road, Anjur Phata",
                            "addressLocality": "Bhiwandi",
                            "addressRegion": "MH",
                            "postalCode": "421302",
                            "addressCountry": "IN"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 19.2813,
                            "longitude": 73.0483
                        },
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "sameAs": [
                            "https://www.facebook.com/profile.php?id=100082135998756&mibextid=ZbWKwL",
                            "https://www.instagram.com/sujalarts2391",
                            "https://www.twitter.com/sujalarts"
                        ]
                    })}
                </script>

                {/* Services Schema.org */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "name": "Sujal Arts Services",
                        "description": "Comprehensive services by Sujal Arts including creative design, printing services, and animation solutions.",
                        "provider": {
                            "@type": "LocalBusiness",
                            "name": "Sujal Arts",
                            "url": "https://www.sujalarts.in",
                            "logo": "https://www.sujalarts.in/path-to-logo.jpg"
                        },
                        "offers": [
                            {
                                "@type": "Service",
                                "name": "Creative Poster Design",
                                "description": "High-quality poster design services for various needs."
                            },
                            {
                                "@type": "Service",
                                "name": "Flyer Design",
                                "description": "Custom flyer design for promotions and events."
                            },
                            {
                                "@type": "Service",
                                "name": "Visiting Card Design",
                                "description": "Professional visiting card design services."
                            },
                            {
                                "@type": "Service",
                                "name": "Banners/Hoardings",
                                "description": "Custom banners and hoardings for advertising."
                            },
                            {
                                "@type": "Service",
                                "name": "E-Invites/Patrika",
                                "description": "Elegant e-invites and digital invitations."
                            },
                            {
                                "@type": "Service",
                                "name": "Brochure Design",
                                "description": "Creative brochure design for business and events."
                            },
                            {
                                "@type": "Service",
                                "name": "Pamphlet Design",
                                "description": "Custom pamphlet design services."
                            },
                            {
                                "@type": "Service",
                                "name": "Standee Design",
                                "description": "Professional standee design services."
                            },
                            {
                                "@type": "Service",
                                "name": "Gifting Items Design",
                                "description": "Custom design for gifting items."
                            },
                            {
                                "@type": "Service",
                                "name": "Stationery Items",
                                "description": "Design services for stationery items."
                            },
                            {
                                "@type": "Service",
                                "name": "Promotional Designs",
                                "description": "Creative designs for promotional materials."
                            },
                            {
                                "@type": "Service",
                                "name": "Religious Work",
                                "description": "Specialized design services for Jainism and other religious needs."
                            },
                            {
                                "@type": "Service",
                                "name": "Logo Design",
                                "description": "Custom logo design services for businesses."
                            },
                            {
                                "@type": "Service",
                                "name": "Printing Services",
                                "description": "High-quality printing services for various needs."
                            },
                            {
                                "@type": "Service",
                                "name": "Book Printing",
                                "description": "Custom book printing services."
                            },
                            {
                                "@type": "Service",
                                "name": "Badges",
                                "description": "Design and printing of badges."
                            },
                            {
                                "@type": "Service",
                                "name": "Gifting Items (Pens, Bags, Stickers, Keychains, Frames)",
                                "description": "Custom design for various gifting items."
                            },
                            {
                                "@type": "Service",
                                "name": "Flags",
                                "description": "Design and printing of custom flags."
                            },
                            {
                                "@type": "Service",
                                "name": "ID Cards",
                                "description": "Custom ID card design and printing services."
                            },
                            {
                                "@type": "Service",
                                "name": "Gate Banner Set",
                                "description": "Custom gate banner design services."
                            },
                            {
                                "@type": "Service",
                                "name": "Custom Prints",
                                "description": "Custom print solutions for various needs."
                            },
                            {
                                "@type": "Service",
                                "name": "Animation Services",
                                "description": "High-quality animation services including motion posters and videos."
                            },
                            {
                                "@type": "Service",
                                "name": "Motion Poster",
                                "description": "Custom motion poster design services."
                            },
                            {
                                "@type": "Service",
                                "name": "Song and Music Video",
                                "description": "Animation services for song and music videos."
                            },
                            {
                                "@type": "Service",
                                "name": "Animation Video",
                                "description": "Creative animation video services."
                            },
                            {
                                "@type": "Service",
                                "name": "Advertising Motion Poster",
                                "description": "Custom motion posters for advertising."
                            },
                            {
                                "@type": "Service",
                                "name": "GIF",
                                "description": "Custom GIF animation services."
                            },
                            {
                                "@type": "Service",
                                "name": "E-Invites Animation",
                                "description": "Animated e-invites design services."
                            }
                        ],
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.sujalarts.in/service?q={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    })}
                </script>

                {/* Additional Meta Tags */}
                <meta name="robots" content="index, follow" />
                <meta name="rating" content="General" />
                <meta name="distribution" content="global" />
                <link rel="canonical" href="https://www.sujalarts.in/service" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:locale:alternate" content="en_US" />
                <meta name="theme-color" content="#317EFB" />
            </Helmet>

            <Hamburger />
            <div className='flex flex-col justify-center items-center w-full h-fit serviceBg lg:pt-[150px] pt-[40px]'>
                <Navbar />
                <Fade direction='down'>

                    <h2 className='montserrat font-bold lg:text-9xl text-5xl text-[#875E54] mb-[80px]  mt-[80px]'>Services</h2>
                </Fade>
                <Fade direction='up'>
                    <h2 className='poppins  text-white md:text-4xl text-xl font-medium text-center mt-20 mb-20 tracking-widest space'>" DESIGN IS THINKING MADE VISUAL. "</h2>
                </Fade>

                {/* Main container  */}
                <div className='flex flex-col justify-start items-center w-full h-fit lg:pt-14 pt-7 lg:pb-14 pb-7'>

                    {/* small div  */}
                    <div className='flex justify-center items-center lg:w-fit w-3/5 h-fit lg:rounded-3xl rounded-xl bg-[#875E54] z-10 absolute'>
                        <h2 className='montserrat text-white lg:text-5xl text-sm font-bold text-nowrap lg:pt-9 lg:pb-9 pt-4 pb-4 pr-9 pl-9'>DESIGNING SERVICES</h2>
                    </div>
                    <div className='flex flex-col justify-center items-start w-4/5 h-fit rounded-3xl bg-slate-500 lg:mt-16 mt-8 lg:pt-20 pt-10 lg:pl-36 pl-9 lg:gap-10  gap-3 lg:pb-20 pb-10 bg-transparent serviceBg boxshadow '>
                        <Fade
                            direction='right'
                            cascade={true}
                            delay={50}  // Reduced delay between items (in milliseconds)
                            duration={300}  // Faster individual animations (in milliseconds)
                            damping={0.2}  // Lower value means faster cascade (0.1 to 1)
                        >
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">CREATIVE POSTER</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">FLYER</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">VISITING CARD DESIGN</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">BANNERS/HOARDING</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">E INVITES/PATRIKA</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">BROCHURE DESIGN</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">PHAMPHLET</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">STANDY DESIGN</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">GIFTING ITEMS DESIGN</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">STATIONERY ITEMS</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">PROMOTIONAL DESIGNS</Link>
                            <Link className='raleway text-[#643F36] lg:text-5xl font-medium text-left' to="/portfolio">RELIGIOUS WORK (ESPECIALLY JAINISM)</Link>
                            <Link to="/porfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >LOGO DESIGN</Link>
                        </Fade>
                    </div>
                </div>

                <div className='flex flex-col justify-start items-center w-full h-fit lg:pt-14 pt-7 lg:pb-14 pb-7 bg-white'>

                    {/* small div  */}
                    <div className='flex justify-center items-center lg:w-fit w-3/5 h-fit lg:rounded-3xl rounded-xl bg-[#875E54] z-10 absolute'>
                        <h2 className='montserrat text-white lg:text-5xl text-sm font-bold text-nowrap lg:pt-9 lg:pb-9 pt-4 pb-4 pr-9 pl-9'>PRINTING SERVICES</h2>
                    </div>
                    <div className='flex flex-col justify-center items-start w-4/5 h-fit rounded-3xl bg-transparent boxshadow lg:mt-16 mt-8 lg:pt-20 pt-10 lg:pl-36 pl-9 lg:gap-10  gap-3 lg:pb-20 pb-10 '>
                        <Fade
                            direction='right'
                            cascade={true}
                            delay={50}  // Reduced delay between items (in milliseconds)
                            duration={300}  // Faster individual animations (in milliseconds)
                            damping={0.2}  // Lower value means faster cascade (0.1 to 1)
                        >
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >BANNERS</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >HOARDINGS</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >STANDY</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >PROMOTIONAL ITEMS</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >BOOK PRINTING</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >BADAGES</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >GIFTING ITEMS (PENS, BAGS, STICKER, KEYCHAINS, FRAME)</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >FLAGS</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >ID CARDS</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >GATE BANNER SET</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >CUSTOM PRINTS AND MANY MORE</Link>
                            <br />
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >:::::NOTES:::::</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >COURIER CHARGES EXTRA</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >BULK ORDER AVAILABLE</Link>
                        </Fade>
                    </div>
                </div>
                <div className='flex flex-col justify-start items-center w-full h-fit lg:pt-14 pt-7 lg:pb-14 pb-7 serviceBg'>

                    {/* small div  */}
                    <div className='flex justify-center items-center lg:w-fit w-3/5 h-fit lg:rounded-3xl rounded-xl bg-[#875E54] z-10 absolute'>
                        <h2 className='montserrat text-white lg:text-5xl text-sm font-bold text-nowrap lg:pt-9 lg:pb-9 pt-4 pb-4 pr-9 pl-9'>ANIMATION SERVICES</h2>
                    </div>
                    <div className='flex flex-col justify-center items-start w-4/5 h-fit rounded-3xl bg-transparent serviceBg boxshadow lg:mt-16 mt-8 lg:pt-20 pt-10 lg:pb-20 pb-10 lg:pl-36 pl-9 lg:gap-10  gap-3'>
                        <Fade
                            direction='right'
                            cascade={true}
                            delay={50}  // Reduced delay between items (in milliseconds)
                            duration={300}  // Faster individual animations (in milliseconds)
                            damping={0.2}  // Lower value means faster cascade (0.1 to 1)
                        >
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >MOTION POSTER</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >SONG AND MUSIC VIDEO</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >ANIMATION VIDEO</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >ADVERTISING MOTION POSTER</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >GIF</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >E INVITES</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >AND MANY MORE VISUAL EFFECTS</Link>
                            <Link to="/portfolio" className='raleway text-[#643F36] lg:text-5xl font-medium text-left' >STANDY DESIGN</Link>
                        </Fade>
                    </div>
                </div>
                <Footer />

            </div>
        </>

    )
}

export default Service
