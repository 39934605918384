import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Media from '../Assests/Media';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import '../Components/Testimonial.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

export default function Testimonials() {
    return (
        <div className='flex flex-col justify-center items-center bg-[#F4EDE6] w-full h-screen'>
            <h2 className='montserrat lg:text-7xl text-3xl font-normal text-[#875E54] mt-[50px]'>TESTIMONIALS</h2>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper mb-[]"
            >
                <SwiperSlide>
                    <div className='flex flex-col w-full h-fit justify-center gap-5 items-center '>
                        <div className='flex justify-center items-center w-28 h-28 overflow-hidden rounded-full'>
                            <img className="scale-150" alt="customer reviews" src={Media.profile} />
                        </div>
                        <h2 className='montserrat font-bold text-2xl  text-[#875E54]'>Nisha Patel</h2>
                        <p className='montserrat text-xs w-4/5 font-medium  '>"Sujal Arts created beautiful designs that truly represent our Jain values. We are very happy with his work!"
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='flex flex-col w-full h-fit justify-center gap-5 items-center'>
                        <div className='flex justify-center items-center w-28 h-28 overflow-hidden rounded-full'>
                            <img className="scale-150" alt="customer reviews" src={Media.profile} />
                        </div>
                        <h2 className='montserrat font-bold text-2xl  text-[#875E54]'>Ramesh Shah</h2>
                        <p className='montserrat text-xs w-4/5 font-medium  '>"Sujal Arts designs capture the essence of Jainism perfectly. Our website looks amazing. Thank you!"</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='flex flex-col w-full h-fit justify-center gap-5 items-center'>
                        <div className='flex justify-center items-center w-28 h-28 overflow-hidden rounded-full'>
                            <img className="scale-150" alt="customer reviews" src={Media.profile} />
                        </div>
                        <h2 className='montserrat font-bold text-2xl  text-[#875E54]'>Amit Dohsi</h2>
                        <p className='montserrat text-xs w-4/5 font-medium  '>Sujal Arts designs have greatly improved our outreach. His work is both respectful and creative.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='flex flex-col w-full h-fit justify-center gap-5 items-center'>
                        <div className='flex justify-center items-center w-28 h-28 overflow-hidden rounded-full'>
                            <img className="scale-150" alt="customer reviews" src={Media.profile} />
                        </div>
                        <h2 className='montserrat font-bold text-2xl  text-[#875E54]'>Priya Mehta</h2>
                        <p className='montserrat text-xs w-4/5 font-medium  '>"Sujal Arts graphic designs are stunning and incorporate Jain elements beautifully. We love the results."</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='flex flex-col w-full h-fit justify-center gap-5 items-center'>
                        <div className='flex justify-center items-center w-28 h-28 overflow-hidden rounded-full'>
                            <img className="scale-150" alt="customer reviews" src={Media.profile} />
                        </div>
                        <h2 className='montserrat font-bold text-2xl  text-[#875E54]'>Kavita Jain</h2>
                        <p className='montserrat text-xs w-4/5 font-medium  '>"Sujal Arts work has transformed our digital presence with his unique and thoughtful designs. We are very grateful."
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}
