import React from 'react';
import '../stickycall/Call.css'

const Call = (props) => {
    const handleImageClick = () => {
        window.location.href = props.redirect;
    };
    return (
        <div className='sticky-container'>

            <div className={props.class}>
                <img onClick={handleImageClick} className={props.image_class} src={props.image} alt="" />
            </div>
        </div>
    )
}

export default Call
