import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Media from '../Assests/Media';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const [email, setEmail] = useState('');

    const navigate = useNavigate();
    const handleMessage = () => {
        navigate('/contact', { state: { email: email } })
    }

    const handleNavigate = (url) => {
        window.location.href = url
    }
    return (
        // mainest container 
        <div className='flex flex-col justify-center items-center bg-[#F7E2DD] w-full h-fit'>

            {/* // main container */}
            <div className='flex flex-row justify-center gap-3 items-start mt-[120px]'>

                {/* column one  */}
                <div className='flex flex-col justify-start items-start lg:gap-5 gap-2 w-1/5'>
                    <h2 className='raleway cursor-default text-[#875E54] lg:text-5xl text-xl font-semibold mb-4'>Menu</h2>
                    <Link className='raleway lg:text-2xl text-sm text-[#875E54] font-medium' to='/'>Home</Link>
                    <Link className='raleway lg:text-2xl text-sm text-[#875E54] font-medium' to='/portfolio'>Portfolio</Link>
                    <Link className='raleway lg:text-2xl text-sm text-[#875E54] font-medium' to='/service'>Services</Link>
                    <Link className='raleway lg:text-2xl text-sm text-[#875E54] font-medium' to='/about'>About Us</Link>
                    <Link className='raleway lg:text-2xl text-sm text-[#875E54] font-medium' to='/contact'>Contact</Link>
                </div>
                {/* column two  */}
                <div className='flex flex-col justify-start items-start gap-5 w-2/5'>
                    <h2 className='raleway cursor-pointer text-[#875E54] lg:text-5xl text-xl font-semibold mb-4'>Contact us</h2>
                    <p className='raleway lg:text-2xl text-sm text-[#875E54] font-medium w-4/5 cursor-pointer'>Global Warehouse, F Building, Gala No. 14, Near Narayan Tadpatri, opp. Kasturi Complex,
                        Dapoda Road, Anjur Phata, Bhiwandi-421302
                    </p>
                    <a href='https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=GTvVlcSHvbJLHwjklJzNBdcVChMHrPBkzCbtXRqTHLNFcZXKktHfftkvpLpHhnwgtDqwXBClCLRJL' className='raleway lg:text-2xl text-sm text-[#875E54] font-medium w-full cursor-pointer'> sujalarts239@gmail.com
                    </a>

                </div>
                {/* column two  */}
                <div className='flex flex-col justify-start items-start gap-5 w-1/5'>
                    <h2 className='raleway text-[#875E54] lg:text-5xl text-xl font-semibold mb-4'>Follow Us</h2>

                    {/* social links div  */}
                    <div className='flex flex-row gap-1 justify-start items-center w-full h-fit'>
                        {/* Logo div  */}
                        <div onClick={() => handleNavigate('https://www.instagram.com/sujalarts2391?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==')} className=' cursor-pointer flex justify-center items-center lg:w-14 w-10 lg:h-14 h-fit bg-stone-800 rounded-full overflow-hidden'>
                            <img className='bg-[#F7E2DD] scale-110' src={Media.instagramlogo} alt="" />
                        </div>
                        {/* Logo div  */}
                        <div onClick={() => handleNavigate('https://www.facebook.com/profile.php?id=100082135998756&mibextid=ZbWKwL')} className=' cursor-pointer flex justify-center items-center lg:w-14 w-10 lg:h-14 h-fit bg-stone-800 rounded-full overflow-hidden'>
                            <img className='bg-[#F7E2DD] scale-110' src={Media.facebooklogo} alt="" />
                        </div>
                        {/* Logo div  */}
                        <div onClick={() => { handleNavigate('https://www.youtube.com/@sujalarts4944') }} className=' cursor-pointer flex justify-center items-center lg:w-14 w-10 lg:h-14 h-fit bg-stone-800 rounded-full overflow-hidden'>
                            <img className='scale-110' src={Media.youtubelogo} alt="" />
                        </div>
                        {/* Logo div  */}
                        <div onClick={() => handleNavigate("https://wa.me/918080446754")} className=' cursor-pointer flex justify-center items-center lg:w-14 w-10 lg:h-14 h-fit bg-stone-800 rounded-full overflow-hidden'>
                            <img className='scale-110' src={Media.whatsapplogo} alt="" />
                        </div>
                    </div>
                    {/* email footer div  */}
                    <div className='flex flex-row justify-start items-center w-full flex-wrap gap-2'>
                        <input onChange={(e) => setEmail(e.target.value)} className='w-24 lg:w-4/5 h-10 flex justify-center items-center lg:text-3xl text-sm montserrat font-medium text-[#BEA8A3] rounded-full lg:pl-10 pl-4' type="email" placeholder='Your Email' />
                        <div onClick={handleMessage} className='lg:w-10 lg:h-10 w-8 h-fit rounded-full overflow-hidden'>
                            <img src={Media.send} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-4/5 h-[2px] bg-[#875E54] lg:mt-[50px] mt-[25px] mb-[25px] lg:mb-[50px] rounded-full'>
            </div>

            <h2 className='montserrat lg:text-3xl text-lg text-[#875E54] font-normal mt-[20px] '>2024. All rights reserved</h2>
            <h2 onClick={() => handleNavigate('https://www.techjoy.in')} className='montserrat cursor-pointer lg:text-xl text-sm text-[#875E54] font-normal lg:mb-[150px] mb-[20px]'>Website by : techjoy.in</h2>
        </div>

    )
}

export default Footer
