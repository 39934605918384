import React from 'react';
import { Link } from 'react-router-dom';
import Hamburger from './Hamburger';

const Navbar = () => {
    return (
        <div>

            <div className='md:flex flex-row justify-evenly items-center w-4/5 h-fit z-20 mb-[50px] lg:mb-[157px] hidden'>
                <Link className='montseraat-nav lg:text-2xl text-sm text-white lg:pr-10 pr-2 lg:pl-10 pl-2 rounded-full font-semibold' to='/'>HOME</Link>
                <Link className='montseraat-nav lg:text-2xl text-sm text-white lg:pr-10 pr-2 lg:pl-10 pl-2 rounded-full font-semibold' to='/portfolio'>PORTFOLIO</Link>
                <Link className='montseraat-nav lg:text-2xl text-sm text-white lg:pr-10 pr-2 lg:pl-10 pl-2 rounded-full font-semibold' to='/service'>SERVICES</Link>
                <Link className='montseraat-nav lg:text-2xl text-sm text-white lg:pr-10 pr-2 lg:pl-10 pl-2 rounded-full font-semibold' to='/about'>ABOUT</Link>
                <Link className='montseraat-nav lg:text-2xl text-sm text-white lg:pr-10 pr-2 lg:pl-10 pl-2 rounded-full font-semibold' to='/contact'>CONTACT</Link>
            </div>
        </div>
    )
}

export default Navbar
