import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Portfolio from './Pages/Portfolio';
import Service from './Pages/Service';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Call from '../src/Components/stickycall/Call.js';
import Media from './Assests/Media.js';

//animation import
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function App() {
  //aos animation code
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 50,
      easing: "ease-out",
      offset: 200,
      mirror: true,
      anchorPlacement: "top-top",
      once: false,
    });
  }, []);

  useEffect(() => {
    Aos.refresh();
  }, []);


  return (
    <div className="App">
      <Router>
        <Call
          class="badge-1"
          image_class="badge-image green"
          image={Media.calllogo}
          redirect="tel:+918080446754"
        />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/service' element={<Service />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
