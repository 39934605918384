import React from 'react';
import Media from '../Assests/Media';
import Card from '../Components/Card';
import Achivement from '../Components/Achivement';
import Footer from '../Components/Footer';
import Testimonials from '../Components/Testimonials';
import Navbar from '../Components/Navbar.js'
import { useNavigate } from 'react-router-dom';
import Hamburger from '../Components/Hamburger.js';
import { Fade } from "react-awesome-reveal";
import { Helmet } from 'react-helmet';



const Home = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/service')
    }


    return (
        <>
            <Helmet>
                <title>Sujal Arts - Graphic Design & Printing Services in Bhiwandi, Mumbai</title>
                <meta name="description" content="Sujal Arts in Bhiwandi, Mumbai offers expert graphic design, printing, and animation services. We design your occasion with creativity and precision." />
                <meta name="keywords" content="Sujal Arts, graphic design, printing, animation, Bhiwandi, Mumbai, Maharashtra, design services, printing services, animation services" />
                <meta name="author" content="Sujal Jain" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Sujal Arts - Graphic Design & Printing Services in Bhiwandi, Mumbai" />
                <meta property="og:description" content="Sujal Arts offers expert graphic design, printing, and animation services in Bhiwandi, Mumbai. We design your occasion with creativity and precision." />
                <meta property="og:url" content="https://www.sujalarts.in" />
                <meta property="og:image" content={Media.logo} />
                <meta property="og:site_name" content="Sujal Arts" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Sujal Arts - Graphic Design & Printing Services in Bhiwandi, Mumbai" />
                <meta name="twitter:description" content="Sujal Arts offers expert graphic design, printing, and animation services in Bhiwandi, Mumbai. We design your occasion with creativity and precision." />
                <meta name="twitter:image" content={Media.logo} />
                <meta name="twitter:site" content="@sujalarts" />
                <meta name="twitter:creator" content="@sujalarts" />

                {/* Google / Schema.org */}
                <meta itemprop="name" content="Sujal Arts - Graphic Design & Printing Services in Bhiwandi, Mumbai" />
                <meta itemprop="description" content="Sujal Arts offers expert graphic design, printing, and animation services in Bhiwandi, Mumbai. We design your occasion with creativity and precision." />
                <meta itemprop="image" content={Media.logo} />

                {/* Local Business Schema.org */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "LocalBusiness",
                        "name": "Sujal Arts",
                        "image": Media.logo,
                        "@id": "",
                        "url": "https://www.sujalarts.in",
                        "telephone": "+91-8080446754",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Global Warehouse, F Building, Gala No. 14, Near Narayan Tadpatri, opp. Kasturi Complex, Dapoda Road, Anjur Phata",
                            "addressLocality": "Bhiwandi",
                            "addressRegion": "MH",
                            "postalCode": "421302",
                            "addressCountry": "IN"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 19.2813,
                            "longitude": 73.0483
                        },
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "sameAs": [
                            "https://www.facebook.com/profile.php?id=100082135998756&mibextid=ZbWKwL",
                            "https://www.instagram.com/sujalarts2391",
                            "https://www.twitter.com/sujalarts"
                        ]
                    })}
                </script>

                {/* Additional Meta Tags */}
                <meta name="robots" content="index, follow" />
                <meta name="rating" content="General" />
                <meta name="distribution" content="global" />
                <link rel="canonical" href="https://www.sujalarts.in" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:locale:alternate" content="en_US" />
                <meta name="theme-color" content="#317EFB" />
            </Helmet>
            <Hamburger />
            <div className='flex flex-col justify-center items-center w-full h-fit'>
                {/* introduction title with monitor image  */}
                <div className='w-full h-fit flex flex-col justify-start items-center'>
                    <img className='w-full h-fit z-0' src={Media.monitor} alt="Welcome to sujal arts we design your occasion" />
                    <div className='flex flex-col w-full h-fit justify-between lg:justify-start items-center gap-1 z-10 absolute bg-none mt-10 lg:mt-[150px]'>
                        <Navbar />
                        <div>

                            <h2 data-aos="fade-up" className='montseraat cursor-default text-white md:text-xl text-xs font-normal text-center'>WELCOME TO</h2>
                            <h1 data-aos="fade-up" className='montseraat cursor-default text-white md:text-9xl text-6xl font-normal text-center text-nowrap' >SUJAL ARTS</h1>
                            <h2 data-aos="fade-up" className='montseraat cursor-default text-white md:text-3xl text-sm font-normal text-center'>WE DESIGN YOUR OCCASION</h2>
                        </div>
                    </div>
                </div>

                {/* card part */}
                <div className='backgroundSkill flex flex-col justify-center items-center w-full h-fit gap-2'>
                    {/* heading  */}
                    <Fade>
                        <h2 className='poppins text-white md:text-3xl text-xl font-semibold text-center mt-20 mb-20 tracking-widest space'>" THE BEST DESIGN IS THE SIMPLEST ONE THAT WORK."</h2>
                    </Fade>

                    {/* actual card container  */}

                    {/* main card container  */}
                    <Card />
                </div>
                <Achivement />
                <Testimonials />
                <button onClick={handleNavigate} className='montserrat text-[#875E54] text-4xl bg-[#F7E2DD] w-11/12 lg:w-96 h-16 rounded-full mt-32 mb-32 hover:bg-[#875E54] hover:text-[#F7E2DD]'>EXPLORE MORE</button>
                <Footer />




            </div>
        </>


    )
}

export default Home
