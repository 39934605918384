import monitor from '../Assests/monitor.png';
import demo from '../Assests/demo.webp';
import design from '../Assests/design.png';
import videoediting from '../Assests/videoediting.png';
import print from '../Assests/print.png';
import send from '../Assests/send.png';
import verticalDemo from '../Assests/verticaldemo.jpg';
import instagramlogo from '../Assests/instagramlogo.png';
import facebooklogo from '../Assests/facebooklogo.png';
import whatsapplogo from '../Assests/whatsapplogo.png';
import youtubelogo from '../Assests/youtubelogo.png';
import calllogo from '../Assests/calllogo.png'

//lgog import
import logo from '../Assests/logo.jpg';

//design images
import design1 from '../Assests/Design/1.webp';
import design2 from '../Assests/Design/2.webp';
import design3 from '../Assests/Design/3.webp';
import design4 from '../Assests/Design/4.webp';
import design5 from '../Assests/Design/5.webp';
import design6 from '../Assests/Design/6.webp';
import design7 from '../Assests/Design/7.webp';
import design8 from '../Assests/Design/8.webp';
import design9 from '../Assests/Design/9.webp';
import design10 from '../Assests/Design/10.webp';
import design11 from '../Assests/Design/11.webp';
import design12 from '../Assests/Design/12.webp';
import design13 from '../Assests/Design/13.webp';
import design14 from '../Assests/Design/14.webp';
import design15 from '../Assests/Design/15.webp';

//printing images
import print1 from '../Assests/Printing/1.webp';
import print2 from '../Assests/Printing/2.webp';
import print3 from '../Assests/Printing/3.webp';
import print4 from '../Assests/Printing/4.webp';
import print5 from '../Assests/Printing/5.webp';
import print6 from '../Assests/Printing/6.webp';
import print7 from '../Assests/Printing/7.webp';
import print8 from '../Assests/Printing/8.webp';
import print9 from '../Assests/Printing/9.webp';
import print10 from '../Assests/Printing/10.webp';
import print11 from '../Assests/Printing/11.webp';
import print12 from '../Assests/Printing/12.webp';
import print13 from '../Assests/Printing/13.webp';
import print14 from '../Assests/Printing/14.webp';
import print15 from '../Assests/Printing/15.webp';
import print16 from '../Assests/Printing/16.webp';
import print17 from '../Assests/Printing/17.webp';
import print18 from '../Assests/Printing/18.webp';
import print19 from '../Assests/Printing/19.webp';

//amination imports
import anim1 from '../Assests/Animation/1.mp4'
import anim3 from '../Assests/Animation/3.mp4'
import anim4 from '../Assests/Animation/4.mp4'
import anim5 from '../Assests/Animation/5.mp4'
import anim6 from '../Assests/Animation/6.mp4'
import anim7 from '../Assests/Animation/7.mp4'
import anim8 from '../Assests/Animation/8.mp4'
import anim9 from '../Assests/Animation/9.mp4'

import profile from '../Assests/profile.jpg';


const Media = {
    profile,
    monitor,
    demo,
    design,
    videoediting,
    print,
    send,
    verticalDemo,
    instagramlogo,
    facebooklogo,
    whatsapplogo,
    youtubelogo,
    calllogo,
    design1,
    design2,
    design3,
    design4,
    design5,
    design6,
    design7,
    design8,
    design9,
    design10,
    design11,
    design12,
    design13,
    design14,
    design15,
    print1,
    print2,
    print3,
    print4,
    print5,
    print6,
    print7,
    print8,
    print9,
    print10,
    print11,
    print12,
    print13,
    print14,
    print15,
    print16,
    print17,
    print18,
    print19,
    anim1,
    anim3,
    anim4,
    anim5,
    anim6,
    anim7,
    anim8,
    anim9,
    logo,
}

export default Media;