import React from 'react';
import Media from '../Assests/Media';
import { useNavigate } from 'react-router-dom';
import { Fade } from "react-awesome-reveal";


const Card = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/service')
    }

    return (

        <div className='flex flex-row w-full h-fit justify-center items-center flex-wrap gap-10 mb-20 ml-8'>
            <Fade cascade={true} direction='left'>

                {/* single card  */}
                <div onClick={handleNavigate} className='cursor-pointer flex flex-col justify-between items-center md:w-96 w-11/12 h-fit rounded-3xl gap-4 bg-white'>

                    {/* image div  */}
                    <div className='flex justify-center items-center w-11/12 h-96 overflow-hidden m-1 rounded-3xl'>

                        {/* actual image  */}
                        <img className='scale-150 ' src={Media.design} alt="" />
                    </div>

                    {/* heading */}
                    <h2 className='montseraat text-3xl font-bold text-red-600 '>DESIGNING</h2>

                    {/* paragraph  */}
                    <p className='poppins text-xl w-11/12 mb-4'>We provide creative design services, helping you plan and visualize your projects with expert sketches and digital tools.
                    </p>
                </div>

                <div onClick={handleNavigate} className='cursor-pointer flex flex-col justify-between items-center md:w-96 w-11/12 h-fit rounded-3xl gap-4 bg-white'>

                    {/* image div  */}
                    <div className='flex justify-center items-center w-11/12 h-96 overflow-hidden m-1 rounded-3xl'>

                        {/* actual image  */}
                        <img className='scale-150 ' src={Media.print} alt="" />
                    </div>

                    {/* heading */}
                    <h2 className='montseraat text-3xl font-bold text-red-600 '>PRINTING</h2>

                    {/* paragraph  */}
                    <p className='poppins text-xl w-11/12 mb-4'>Our printing services offer high-quality reproduction of text and images on paper or other materials, using advanced printing technology.

                    </p>

                </div>
                <div onClick={handleNavigate} className='cursor-pointer flex flex-col justify-between items-center md:w-96 w-11/12 h-fit rounded-3xl gap-4 bg-white'>

                    {/* image div  */}
                    <div className='flex justify-center items-center w-11/12 h-96 overflow-hidden m-1 rounded-3xl'>

                        {/* actual image  */}
                        <img className='scale-150 ' src={Media.videoediting} alt="" />
                    </div>

                    {/* heading */}
                    <h2 className='montseraat text-3xl font-bold text-red-600 '>ANIMATION</h2>

                    {/* paragraph  */}
                    <p className='poppins text-xl w-11/12 mb-4'>We create engaging animations, bringing your ideas to life with techniques like hand-drawn art, stop-motion, and computer graphics.

                    </p>

                </div>
            </Fade>


        </div>

    )
}

export default Card
